import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import NewsletterSubscriptionBlog from "../components/newsletter-subscription-blog"
import Img from "gatsby-image"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Video from "../components/video"
import SEO from "../components/seo"
import SecondaryCta from "../components/secondary-cta"


const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <h2>{children}</h2>,
    [INLINES.HYPERLINK]: (node) => {
      if (node.data.uri.indexOf('youtube.com') !== -1) {
        return (
          <Video videoSrcURL={node.data.uri} />
        );
      }
      return <a href={node.data.uri}>{node.content[0].value}</a>
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => (
      <div id="event-post-content-embedded-asset">
        <Img fluid={node.data.target.fluid} imgStyle={{ objectFit: "contain" }} className="embedded-asset"/>
        <figcaption className="caption">
          {node.data.target.description}
        </figcaption>
      </div>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {

      if (!node || !node.data || !node.data.target) {
        return <></>
      }

      const target = node.data.target;

      if (node.data.target.__typename === "ContentfulCta") {
        const lien = target.lien && target.lien.toLowerCase();
        const texte = target.texte || "Go";

        if (!texte || !lien) {
          return <></>
        }

        if (lien.startsWith('http')) {
          return <div id="blog-post-content-embedded-cta">
            <SecondaryCta text={texte} externalLink={target.lien}/>
          </div>
        }

        return <div id="blog-post-content-embedded-cta">
          <SecondaryCta text={texte} link={target.lien}/>
        </div>
      }

      if (node.data.target.__typename === "ContentfulClickableImage") {

        const asset = node.data.target.asset;
        const link = node.data.target.link;
        if (!asset || !link) {
          return <></>
        }
        return (
          <div id="blog-post-content-embeded-asset" className="clickable-asset" onClick={() => window.location.href = link}>
            <Img fluid={asset.fluid} imgStyle={{ objectFit: "contain" }} className="embeded-asset"/>
            <figcaption className="caption">
              {asset.description}
            </figcaption>
          </div>
        )
      }
    },
  }
}

class EventPost extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    const {
      contentfulEvent: { title, image, content, presenter, date, place, excerpt, slug},
    } = this.props.data;

    const metaImage = {
      src: image.fluid.src,
    }

    const d = new Date(date)
    const seo = <SEO lang='fr' title={title} description={excerpt} metaImage={metaImage} url={"https://amicaleststan.fr/events"+slug}/>

    return (
      <Layout path={"/events"} color={'#FAFAFA'} hideNewsletter={true} seo={seo}>
        <div id="event-post-header">
          <h1 id="header_title">{title}</h1>
          <div id="header_subtitles">
            <span className="presenter-profile"
                  style={{
                    background: `url(${presenter.photo.file.url}) no-repeat center center`,
                    backgroundSize: 'cover'
                  }}
            />
            <strong>{presenter.nom},  </strong>
            <span>Prochaine date : {d.toLocaleDateString("fr-FR", { year: "numeric", month: "long", day: "numeric" })}</span>
            {place && <span > @{place} </span>}
          </div>
        </div>
        <div id="event-post-header-image">
          <Img fluid={image.fluid} imgStyle={{ objectFit: "contain" }}/>
        </div>
        <div id="event-post-content">
          {renderRichText(content, options)}
        </div>
      </Layout>
    )
  }
}


export default EventPost

export const eventPostQuery = graphql`
  query eventPostQuery($slug: String!) {
    contentfulEvent(slug: { eq: $slug }) {
      slug
      title
      date
      excerpt
      image { fluid(maxHeight: 500, quality: 100){
        ...GatsbyContentfulFluid_withWebp
        src
        }
        description
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            fluid(maxWidth: 600, quality: 100){
                ...GatsbyContentfulFluid_withWebp
            }
            description
          }
          ... on ContentfulCta {
            __typename
            texte
            lien
            contentful_id
          }
        }
      }
      place
      presenter {
        nom
        photo {
          file {
            url
          }
        }
      }
    }
  }
`
